import React, { useState } from 'react'
// import Carousel from 'react-bootstrap/Carousel'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as S from './styles';
import styles from './Slideshow.module.css'
import { css, jsx } from "@emotion/core";
import BlessNestLogo from '../../assets/images/KIMRGB.svg'

const images = [
    require('../../assets/images/slideshow/IMG_1396.jpg'),
    require('../../assets/images/slideshow/IMG_1392.jpg'),
    require('../../assets/images/slideshow/IMG_1398.jpg'),
    // require('../../assets/images/slideshow/IMG_1505.jpg'),
    // require('../../assets/images/slideshow/IMG_1508.jpg'),
    // require('../../assets/images/slideshow/IMG_1524.jpg'),
    require('../../assets/images/slideshow/IMG_1529.jpg'),
    require('../../assets/images/slideshow/IMG_1535.jpg'),
    // require('../../assets/images/slideshow/IMG_1555.jpg'),
    require('../../assets/images/slideshow/IMG_1568.jpg'),
    // require('../../assets/images/slideshow/IMG_1577.jpg'),
    require('../../assets/images/slideshow/IMG_4928.jpg'),
]

const SlideShow = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <S.Wrapper>
            <Carousel autoPlay showThumbs={false} showIndicators={false} infiniteLoop stopOnHover showStatus={false} swipeable className={styles.carousel}>
                {images.map((img, i) => {
                    console.log('IMG = ', img)
                    return(
                    <div style={{ height: '100%' }}>
                        <S.Image src={img} key={i}/>
                    </div>
                    )
                })}
                {/* <div style={{ height: '100%' }}>
                    <S.Image src={require("../../assets/images/house1/68627283_2176254759151315_6699309530401996800_n.jpg")} />
                    <img src={require("../../assets/images/house1/68627283_2176254759151315_6699309530401996800_n.jpg")} style={{ height: '100%' }} />
                    <S.Legend >
                        <img src={BlessNestLogo} css={css`width: 700px; height: 500px; margin-left: auto; margin-right: auto; overflow-y: visible !important; @media(max-width: 600px {width: 600px; height: 500px;})`} />
                    </S.Legend>
                    <S.Legend className="legend">Bring life to homes and get more money from your investment</S.Legend>
                    <p className="legend">Bring life to homes and get more money from your investment</p>
                    <p>Test</p>
                </div> */}

            </Carousel>
            <S.Legend >
                <img src={BlessNestLogo} css={css`width: 700px; height: 500px; margin-left: auto; margin-right: auto; overflow-y: visible !important; @media(max-width: 600px) {width: 300px; height: 200px;})`} />
            </S.Legend>
        </S.Wrapper>
    );
    // return (
    //     <Carousel activeIndex={index} onSelect={handleSelect} style={{ height: 600 }}>
    //         <Carousel.Item>
    //             <img
    //                 className="d-block w-100"
    //                 src="holder.js/800x400?text=First slide&bg=373940"
    //                 alt="First slide"
    //             />
    //             <Carousel.Caption>
    //                 <h3 style={{ color: 'black' }}>First slide label</h3>
    //                 <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    //             </Carousel.Caption>
    //         </Carousel.Item>
    //         <Carousel.Item>
    //             <img
    //                 className="d-block w-100"
    //                 src="holder.js/800x400?text=Second slide&bg=282c34"
    //                 alt="Second slide"
    //             />

    //             <Carousel.Caption>
    //                 <h3>Second slide label</h3>
    //                 <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //             </Carousel.Caption>
    //         </Carousel.Item>
    //         <Carousel.Item>
    //             <img
    //                 className="d-block w-100"
    //                 src="holder.js/800x400?text=Third slide&bg=20232a"
    //                 alt="Third slide"
    //             />

    //             <Carousel.Caption>
    //                 <h3>Third slide label</h3>
    //                 <p>
    //                     Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    //               </p>
    //             </Carousel.Caption>
    //         </Carousel.Item>
    //     </Carousel>
    // );
}

export default SlideShow
